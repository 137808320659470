import React from "react";
import footerFeghasLogoIcon from "../assets/feghasLogoIcon2.svg";
import footerFeghasLogo from "../assets/feghasAcadWhiteLogo.png";
import arrowUpIcon from "../assets/arrowupminor-svgrepo-com.svg";

const AcademyFooter = () => {
  return (
    <footer>
      <div className="innerAcadFooter">
        <div className="footersListWrap">
          <div className="footerImageContents">
            <img src={footerFeghasLogo} alt="" className="feghasLogo" />
            <p className="footerSubtext">
              Unlock endless possibilities with Feghas Academy.
            </p>
            <div className="powered">
              <img src={footerFeghasLogoIcon} alt="" className="logoIcon" />
              <span className="poweredText">
                <p className="poweredTexttitle">powered by</p>
                <p className="poweredSubtext">
                  Feghas innovation Academy
                  <img src={arrowUpIcon} alt="" className="arrowUp" />
                </p>
              </span>
            </div>
          </div>

          <ul className="footerHeadLists">
            <a href="/academy">
              <li className="footerLinks">Home</li>
            </a>
            <a href="/About-Us">
              <li className="footerLinks">About Us</li>
            </a>
            <a href="/WhyFeghas">
              <li className="footerLinks">Why Feghas</li>
            </a>
            <a href="/Courses">
              <li className="footerLinks">Courses</li>
            </a>
            <a href="/Register">
              <li className="footerLinks">Contact Us</li>
            </a>
          </ul>

          <ul className="footerHeadLists">
            <a href="#">
              <li className="footerLinks socialMed">Social Mediums</li>
            </a>
            <a href="#">
              <li className="footerLinks">X(Twitter)</li>
            </a>
            <a href="#">
              <li className="footerLinks">Instagram</li>
            </a>
          </ul>
        </div>

        <p className="copyRight">&copy; Feghas 2024. All rights reserved</p>
      </div>
    </footer>
  );
};

export default AcademyFooter;
