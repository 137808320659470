import { Link } from "react-router-dom";
import React, { useRef, useState } from "react";
import "../Main.css";
import feghasLogo from "../assets/Feghassolution_logo.jpg";
import homeIcon from "../assets/homeIcon.svg";
import menuIcon from "../assets/icons8-menu.svg";
import closeIcon from "../assets/icons8-close.svg";

const Header = () => {
  const navRef = useRef();
  //const btnRef = useRef();
  const [close, setClose] = useState(true);

  const showNavbar = () => {
    navRef.current.classList.toggle("responsiveNav");
    // btnRef.current.classList.toggle("responsiveNav");
    setClose((prev) => !prev);
  };

  const closeNavbar = () => {
    setClose((prev) => !prev);
  };

  return (
    <header>
      <img src={feghasLogo} alt="feghasLogo" className="feghasLogo" />

      <div ref={navRef} className="headerWrapper responsiveNav">
        <nav className="navB">
          <ul className="navList">
            <li className="navLink">
              <Link to="/" className="homeIconAndText">
                <img src={homeIcon} alt="home" className="home" />
                <span className="homeFeghasUndeline" onClick={showNavbar}>
                  {" "}
                  Home Feghas Solution{" "}
                </span>
              </Link>
            </li>
            <li className="navLink">
              <Link to="/aboutus" onClick={showNavbar}>
                {" "}
                About Us{" "}
              </Link>
            </li>
            <li className="navLink">
              <Link to="/solutions" onClick={showNavbar}>
                {" "}
                Solutions{" "}
              </Link>
            </li>
            <li className="navLink">
              <Link to="/careers" onClick={showNavbar}>
                {" "}
                Careers{" "}
              </Link>
            </li>
            <li className="navLink">
              <Link to="/academy" onClick={showNavbar}>
                {" "}
                Academy{" "}
              </Link>
            </li>
            <li className="navLink">
              <Link to="/blog" onClick={showNavbar}>
                {" "}
                Blog{" "}
              </Link>
            </li>
          </ul>
        </nav>

        <Link to="/contactus" className="contactBtnn">
          {" "}
          Contact Us
        </Link>
      </div>
      {close ? (
        <img
          src={menuIcon}
          alt=""
          className="menu sr-only"
          onClick={showNavbar}
        />
      ) : (
        <img
          src={closeIcon}
          alt=""
          className="menu sr-only"
          onClick={showNavbar}
        />
      )}
      {/* <img src={menuIcon} alt="" className='menu sr-only' onClick={showNavbar}/> */}
    </header>
  );
};

export default Header;
