import React, { useEffect, useState } from "react";
import "../Main.css";
import imageIcon from "../assets/laptop.svg";
import arrowLeft from "../assets/arrow-sm-left-svgrepo-com.svg";
import feghasLogo from "../assets/Feghassolution_logo.jpg";
import { Link } from "react-router-dom";
import FormFeedBackMain from "../FormFeedback/FormFeedBackMain";

const Careers = () => {
  const [submitStatus, setSubmitStatus] = useState(null);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    linkToPortfolio: "",
    role: "",
    coverLetter: "",
    employerMessage: "",
  });

  useEffect(() => {
    document.title = "Careers | Feghas Solutions";
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbwN4N5Gk5SXyGRroz95yeN5CE1JNlt10MpB2AhkC3kEqaRwz-fxmDocmtB-KogEVWp2CQ/exec",
        {
          method: "POST",
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();
      if (result.result === "success") {
        setSubmitStatus({
          type: "success",
          message: "Form submitted successfully!",
          messageSubText:
            "Please do constant check your mail for follow ups and other informations",
        });

        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          mobileNumber: "",
          linkToPortfolio: "",
          role: "",
          coverLetter: "",
          employerMessage: "",
        });
      } else {
        setSubmitStatus({
          type: "error",
          message: "Failed to submit form!",
          messageSubText: "Please try again",
        });
      }
    } catch (error) {
      setSubmitStatus({
        type: "error",
        message: "Failed to submit form due to a network error!",
        messageSubText: "Please check your connections",
      });
    }
  };
  return (
    <section className="careers">
      <div className="innerCareers">
        <div className="primaryContent">
          <img src={feghasLogo} alt="" className="feghasLogo" />
          <div className="joinHeadTxt">
            <h1 className="joinTxt">Join the team</h1>
            <p className="joinSubtext">Be part of our journey</p>
          </div>

          <img src={imageIcon} alt="" className="laptopIcon" />
        </div>

        <div className="secondaryContent">
          <div className="innerSecondaryContent">
            <Link to="/" className="backToHome">
              <img src={arrowLeft} alt="" className="arrowBack" />
              Back to Homepage{" "}
            </Link>

            <p className="jobDescription">
              Job Description Goes here:
              <span className="nextDescript">
                It happens, a position you are interested in might not be
                available. Nevertheless, we admire talent at Feghas solutions.
                If you think you have what it takes to join the team at Feghas
                Solutions why not shoot your shot.
              </span>
            </p>
            <form action="" className="careerForm" onSubmit={handleSubmit}>
              <div className="primaryDetails">
                <div className="labelAndInput">
                  <label htmlFor="firstName" className="careerLabel">
                    First Name
                  </label>
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    className="personalDetailsInput"
                    placeholder="Type your message here"
                    required
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                </div>

                <div className="labelAndInput">
                  <label htmlFor="lastName" className="careerLabel">
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    className="personalDetailsInput"
                    placeholder="Type your message here"
                    required
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                </div>

                <div className="labelAndInput">
                  <label htmlFor="email" className="careerLabel">
                    Email
                  </label>
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="personalDetailsInput"
                    placeholder="Type your message here"
                    required
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>

                <div className="labelAndInput">
                  <label htmlFor="mobileNumber" className="careerLabel">
                    Mobile Number
                  </label>
                  <input
                    type="tel"
                    name="mobileNumber"
                    id="mobileNumber"
                    className="personalDetailsInput"
                    placeholder="Type your message here"
                    required
                    value={formData.mobileNumber}
                    onChange={handleChange}
                  />
                </div>
              </div>

              <div className="labelAndInput displayBlock">
                <label htmlFor="linkToPortfolio" className="careerLabel">
                  Link to portfolio
                </label>
                <input
                  type="text"
                  name="linkToPortfolio"
                  id="linkToPortfolio"
                  className="personalDetailsInput"
                  placeholder="Type your message here"
                  required
                  value={formData.linkToPortfolio}
                  onChange={handleChange}
                />
              </div>

              <div className="labelAndInput displayBlock">
                <label htmlFor="role" className="careerLabel">
                  Role
                </label>
                <input
                  type="text"
                  name="role"
                  id="role"
                  className="personalDetailsInput"
                  placeholder="Type your message here"
                  required
                  value={formData.role}
                  onChange={handleChange}
                />
              </div>

              <div className="labelAndInput displayBlock">
                <label htmlFor="coverLetter" className="careerLabel">
                  Cover Letter
                </label>
                <textarea
                  name="coverLetter"
                  id="coverLetter"
                  cols="30"
                  rows="10"
                  className="personalDetailsInput"
                  placeholder="Type your message here"
                  required
                  value={formData.coverLetter}
                  onChange={handleChange}
                ></textarea>
              </div>

              <div className="labelAndInput displayBlock">
                <label htmlFor="employerMessage" className="careerLabel">
                  Any other message to employer
                </label>
                <textarea
                  name="employerMessage"
                  id="employerMessage"
                  cols="30"
                  rows="10"
                  className="personalDetailsInput"
                  placeholder="Type your message here"
                  value={formData.employerMessage}
                  onChange={handleChange}
                ></textarea>
              </div>

              <button type="submit" className="submitBtn">
                Submit
              </button>
            </form>
          </div>
        </div>

        {submitStatus && (
          <FormFeedBackMain
            type={submitStatus.type}
            message={submitStatus.message}
            messageSubText={submitStatus.messageSubText}
          />
        )}
      </div>
    </section>
  );
};

export default Careers;
