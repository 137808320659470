import React, { useState } from "react";
import "../Main.css";
import feghasLogoGrayIcon from "../assets/Feghassolution_logo.jpg";
import instagramIcon from "../assets/instagram-svgrepo-com.svg";
import facebookIcon from "../assets/facebook-svgrepo-com (1).svg";
import tictokIcon from "../assets/tiktok-fill.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  const [formData, setFormData] = useState({
    email: "",
  });
  const [show, setShow] = useState(false);

  /*   const handleShow = () => {
    setShow((prev) => !prev);
  }; */

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbzO3ulTrS9c-lMKXXiogr7oMMIcy-YuZq7g8gaEDeXPtHVTXOmGkTg0arxvbfhS4K8/exec",
        {
          method: "POST",
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();
      if (result.result === "success") {
        setShow(true);
        setFormData({ email: "" });
      }
    } catch (error) {
      /*  console.error("Error:", error); */
      setShow(false);
      setFormData({ email: "" });
    }
  };
  return (
    <footer>
      <div className="innerFooter">
        <div className="topFooter">
          <div className="footerFirstWrap">
            <img src={feghasLogoGrayIcon} alt="" className="footerFeghasLogo" />

            <ul className="footTitleList">
              <li className="prodTitle footTitle"> Products </li>
              <li className="prod">Tertiary Education Portal</li>
              <li className="prod">Land Management Solution</li>
              <li className="prod">Revenue Collection Management System</li>
              <li className="prod">Secondary School Solution</li>
            </ul>

            <ul className="footTitleList">
              <li className="supportTitle footTitle"> Support </li>

              <Link to="/careers" className="prod">
                Careers
              </Link>
              <Link to="/aboutus" className="prod">
                About Us
              </Link>
              <Link to="/" className="prod">
                Blog
              </Link>
              <Link to="/contactus" className="prod">
                Contact Us
              </Link>
            </ul>

            <ul className="footTitleList">
              <li className="legalTitle footTitle"> Legal </li>
              <li className="prod">Privacy Policy</li>
              <li className="prod">Terms and Condition</li>
            </ul>

            <div className="newsLetter">
              <p className="news">sign up for newsletter</p>
              <form className="newsForm" onSubmit={handleSubmit}>
                <label htmlFor="" className="newsEmailLabel">
                  Email for Newsletter
                </label>
                <input
                  type="email"
                  name="email"
                  className="newsInput"
                  placeholder="Johndoe@example.com"
                  value={formData.email}
                  onChange={handleChange}
                />
                <button
                  style={show ? { backgroundColor: "orange" } : null}
                  className="newsBtn"
                  type="submit"
                >
                  {show ? "Subscribed" : "Subscribe"}
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="copyrightAndSocial">
          <div className="socials">
            <a href="#">
              <img src={instagramIcon} alt="" className="footerSocials" />
            </a>
            <a href="#">
              <img src={tictokIcon} alt="" className="footerSocials" />
            </a>
            <a href="#">
              <img src={facebookIcon} alt="" className="footerSocials fb" />
            </a>
          </div>

          <p className="copyright">&copy; copyright reserved 2024</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
