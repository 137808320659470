import React, { useEffect, useState } from "react";
import arrowLeft from "../assets/left-arrow-alt-svgrepo-com.svg";
import failIcon from "../assets/success-svgrepo-com.svg";
import { Link } from "react-router-dom";
import FormFeedback from "../FormFeedback/FormFeedback";

const Register = ({ regsiterImageSlider, autoPlayTime = 3000 }) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    course: "",
  });
  const [currentSlide, setCurrentSlide] = useState(0);
  const [submitStatus, setSubmitStatus] = useState(null);

  function nextSlide(slideIndex = currentSlide + 1) {
    const nextSlideIndex =
      slideIndex >= regsiterImageSlider.length ? 0 : slideIndex;

    setCurrentSlide(nextSlideIndex);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      nextSlide();
    }, autoPlayTime);

    return () => clearTimeout(timer);
  }, [currentSlide]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbwvcGpPY87C0WBPk8yrGqaNQMPUjje7Ud7g6NZbv9dXUgPPAJrVBZKgrMaVXr-XesM/exec",
        {
          method: "POST",
          body: JSON.stringify(formData),
        }
      );

      const result = await response.json();
      if (result.result === "success") {
        setSubmitStatus({
          type: "success",
          message: "Form submitted successfully!",
          messageSubText:
            "Please do constant check your mail for follow ups and other informations",
        });

        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          mobileNumber: "",
          course: "",
        });
      } else {
        setSubmitStatus({
          type: "error",
          message: "Failed to submit form!",
          messageSubText: "Please try again",
        });
      }
    } catch (error) {
      //console.error("Error:", error);
      setSubmitStatus({
        type: "error",
        feedImage: failIcon,
        message: "Failed to submit form due to a network error!",
        messageSubText: "Please check your connections",
      });
    }
  };

  return (
    <section className="register">
      <div className="innerRegister">
        <form action="" className="registerForm" onSubmit={handleSubmit}>
          <Link to="/academy" className="backToWeb">
            <img src={arrowLeft} alt="" className="arrowLeft" />

            <p className="backToWebText">Back to homepage</p>
          </Link>

          <div className="inputWrap">
            <label htmlFor="firstName" className="regLabel">
              First Name
            </label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              className="registerInput"
              placeholder="First Name"
              required
              value={formData.firstName}
              onChange={handleChange}
            />
          </div>

          <div className="inputWrap">
            <label htmlFor="lastName" className="regLabel">
              Last Name
            </label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              className="registerInput"
              placeholder="Last Name"
              required
              value={formData.lastName}
              onChange={handleChange}
            />
          </div>

          <div className="inputWrap">
            <label htmlFor="email" className="regLabel">
              Email
            </label>
            <input
              type="email"
              name="email"
              id="email"
              className="registerInput"
              placeholder="Email"
              required
              value={formData.email}
              onChange={handleChange}
            />
          </div>

          <div className="inputWrap">
            <label htmlFor="mobileNumber" className="regLabel">
              Mobile Number
            </label>
            <input
              type="tel"
              name="mobileNumber"
              id="mobileNumber"
              className="registerInput"
              placeholder="Mobile Number"
              required
              value={formData.mobileNumber}
              onChange={handleChange}
            />
          </div>

          <div className="inputWrap">
            <label htmlFor="course" className="regLabel">
              Course
            </label>
            <select
              type="text"
              name="course"
              id="course"
              className="registerInput regSelect"
              required
              value={formData.course}
              onChange={handleChange}
            >
              <option selected> none </option>
              <option value="Data Analytics"> Data Analytics </option>
              <option value="Graphics Design"> Graphics Design </option>
              <option value="UI/UX"> UI/UX </option>
              <option value="Front End Development">
                {" "}
                Front End Development{" "}
              </option>
              <option value="MS Soft Skills"> MS Soft Skills </option>
              <option value="Back-End Development">
                {" "}
                Back-End Development{" "}
              </option>
              <option value="Fullstack Development">
                {" "}
                Fullstack Development{" "}
              </option>
              <option value="Internet of Things"> Internet of Things </option>
              <option value="Network"> Network </option>
              <option value="Project Management"> Project Management </option>
              <option value="Cybersecurity"> Cybersecurity </option>
              <option value="Cloud Computing"> Cloud Computing </option>
              <option value="Video Editing"> Video Editing </option>
              <option value="Animation"> Animation </option>
              <option value="Digital Marketing"> Digital Marketing </option>
              <option value="Robotics/AI"> Robotics/AI </option> */
            </select>
            {/* <span className="arrowExpand">
              <img src={arrowDown} alt="" className="arrowDown" />
            </span> */}
          </div>

          <button type="submit" className="registerBtn">
            Submit
          </button>
        </form>

        <div className="registerImage">
          {regsiterImageSlider.map(({ imageSlide }, index) => (
            <div
              className="imageSlideContainer"
              key={index}
              style={{
                backgroundImage: `url(${imageSlide})`,
                marginLeft: index === 0 ? `-${currentSlide * 100}%` : null,
              }}
            ></div>
          ))}

          <div className="sliderText">
            <h1 className="sliderTextHead">
              Learn from <span className="dispBlck">experts</span>{" "}
            </h1>
            <p className="sliderTextSubText">Be part of our journey</p>
          </div>

          <div className="indicator">
            {regsiterImageSlider.map((_, index) => (
              <div
                className="dash"
                key={index}
                style={
                  currentSlide === index ? { opacity: 1 } : { opacity: 0.5 }
                }
                onClick={() => nextSlide(index)}
              ></div>
            ))}
          </div>
        </div>
      </div>

      {submitStatus && (
        <FormFeedback
          type={submitStatus.type}
          message={submitStatus.message}
          messageSubText={submitStatus.messageSubText}
        />
      )}
    </section>
  );
};

export default Register;
